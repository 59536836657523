import { timer } from "@paparazzi/utils/promise";
import api from "@virgodev/bazaar/functions/api";
import copy from "@virgodev/bazaar/functions/copy";
import ua from "ua-parser-js";
import { nextTick } from "vue";
import { useCartStore } from "../cart";
import { useFirebaseStore } from "../firebase";
import { useShopStore } from "../shop";
import { useSocketStore } from "../socket";
import { useUserStore } from "../user";

interface CommandMessage {
  name: string;
  version?: string;
  memory?: any;
  agent?: any;
  app?: boolean;
  rep_number?: string;
  clientid?: string;
  clients: string[];
  title?: string;
  content?: any;
  noresponse?: boolean;
  products?: string;
  categories?: string;
}

export async function executeServerCommand(msg: CommandMessage): Promise<void> {
  const cart = useCartStore();
  const firebase = useFirebaseStore();
  const shop = useShopStore();
  const socket = useSocketStore();
  const user = useUserStore();

  let cmd = msg.name || msg;
  msg.app = true; //this.$options.name === "convention";
  msg.agent = new ua().getResult();
  // msg.memory = navigator.deviceMemory;
  msg.version = window.versionHash;

  msg.products = `${shop.products.length} (${shop.objects.products.length})`;
  msg.categories = `${shop.categories.length} (${shop.objects.categories.length})`;

  msg.rep_number = user.props?.rep_number;
  msg.clientid = firebase.clientId;

  if (
    cmd == "echo" ||
    !msg.clients ||
    msg.clients.includes(firebase.clientId)
  ) {
    if (cmd == "update") {
      const updated = await checkVersion();

      // let updated = await this.checkUpdates();
      msg.content = updated ? "updated" : "not updated";
    } else if (cmd == "clear-localstorage") {
      console.log("clearing localstorage...");
      localStorage.clear();
      localStorage.forceUpdate = "yes";
      location.reload();
      msg.content = `updating...`;
    } else if (cmd == "update-now") {
      console.log("updating...");
      localStorage.forceUpdate = "yes";
      location.reload();
      msg.content = `updating...`;
    } else if (cmd == "console") {
      console.info("message from server", msg);
    } else if (cmd == "alert") {
      let content = msg.content;
      msg.content = "waiting for user...";
      // socket.emit("cmdresponse", msg);
      alert(msg);
      await nextTick();

      // @ts-ignore
      alert(content, msg.title);
      msg.content = "OK";
    } else if (cmd == "sync-shop") {
      msg.content = "resetting shop...";
      // socket.emit("cmdresponse", msg);

      for (const patcher of Object.values(shop.patchers)) {
        await patcher.cache.clear();
      }
      location.reload();

      msg.content = "OK";
    } else if (cmd == "get-cart") {
      msg.content = {
        items: {},
        totals: cart.object.totals,
      };
      cart.object.items.forEach((i) => {
        if (i.product) {
          msg.content.items[i.product.remote_id] = i.quantity;
        }
      });
      // } else if (cmd == "save-cart") {
      //   msg.content = "saving cart...";
      //   this.emit("cmdresponse", msg);

      //   await this.$store.dispatch("updateCart", true);
      //   msg.content = {
      //     items: {},
      //     totals: this.$store.state.shop.cart.totals,
      //   };
      //   this.$store.state.shop.cart.items.forEach((i) => {
      //     msg.content.items[i.product.remote_id] = i.quantity;
      //   });
    } else if (cmd == "user") {
      msg.content = copy(user.props);
    } else if (cmd == "clear-cookies") {
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    } else if (cmd == "logout") {
      console.log("logging out");
      await user.logout();
    } else if (cmd == "echo") {
      // nothing
    }
  } else {
    msg.content = `skipped '${cmd}'`;
    console.log(
      "skipped -- clients",
      firebase.clientId,
      msg.clientid,
      msg.clients,
    );
  }
  if (!msg.noresponse) {
    msg.clients = [];
    console.log("response", msg, msg.version);
    socket.emit("cmdresponse", msg);
  }
}

export let lastCheck = Date.now();
export async function checkVersion(): Promise<boolean> {
  if (
    !document.hidden &&
    window.versionHash &&
    lastCheck < Date.now() - 1000 * 60 * 15
  ) {
    const version = await getVersion();
    if (version) {
      if (version !== window.versionHash) {
        console.warn(" - version stale: reloading");
        localStorage.versionHash = "";
        timer(1000).then(() => {
          location.reload();
        });
        return true;
      }
    }
  }
  return false;
}

export async function getVersion(): Promise<string> {
  const response = await api({ url: "site/version/" });
  if (response.ok) {
    return response.body.version;
  }
  return "";
}
