<template>
  <MyAutoForm
    @update:modelValue="emit('update:modelValue', $event)"
    @submit="emit('submit', $event)"
    :modelValue="props.modelValue"
    :fields="props.fields"
    :defaults="props.defaults"
    :sub="props.sub"
    :errors="props.errors"
    :form-component="MyAutoForm"
  >
    <template
      v-for="(index, name) in $slots"
      v-slot:[name]="{ field, updateField }"
    >
      <slot :name="name" :field="field" :updateField="updateField" />
    </template>
  </MyAutoForm>
</template>

<script setup lang="ts">
import type { FieldDef } from "@virgodev/vue-models/components/forms/defs";
import MyAutoForm from "./MyAutoForm.vue";

const props = defineProps<{
  fields: FieldDef[];
  modelValue: Object;
  errors?: Object;
  defaults?: Object;
  sub?: boolean;
}>();
const emit = defineEmits(["submit", "update:modelValue"]);
</script>
