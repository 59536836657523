import { useUserStore } from "@/stores/user";
import { createHead } from "@unhead/vue";
import { createPinia } from "pinia";
// import BadgeDirective from "primevue/badgedirective";
import * as Sentry from "@sentry/vue";
import PrimeVue from "primevue/config";
import { createApp as _createApp, createSSRApp } from "vue";
import App from "./App.vue";
import { createRouter } from "./router/index";

import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";

export function createApp() {
  const app = import.meta.env.SSR ? createSSRApp(App) : _createApp(App);
  // const app = createSSRApp(App)
  // const app = _createApp(App)
  const pinia = createPinia();
  const head = createHead();
  const router = createRouter();
  app.use(pinia);
  app.use(head);
  app.use(router);
  app.use(PrimeVue);
  app.use(ToastService);
  app.directive("tooltip", Tooltip);

  // if (import.meta.env.MODE === "PRODUCTION") {
  //   Sentry.init({
  //     app,
  //     dsn: import.meta.env.VITE_APP_RAVEN_DSN,
  //     integrations: [
  //       Sentry.browserTracingIntegration({ router }),
  //       Sentry.replayIntegration(),
  //     ],

  //     // Set tracesSampleRate to 1.0 to capture 100%
  //     // of transactions for tracing.
  //     // We recommend adjusting this value in production
  //     tracesSampleRate: 1.0,

  //     // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  //     tracePropagationTargets: [
  //       "localhost",
  //       /^https:\/\/paparazziaccessories\.com\//,
  //     ],

  //     // Capture Replay for 10% of all sessions,
  //     // plus for 100% of sessions with an error
  //     replaysSessionSampleRate: 0.1,
  //     replaysOnErrorSampleRate: 1.0,
  //   });
  // }

  const user = useUserStore();
  app.mixin({
    computed: {
      user() {
        return user.props;
      },
    },
  });

  // ssr node has no window
  if (typeof window !== "undefined") {
    app.name = "site";
    window.app = app;
  }

  // app.directive("badge", BadgeDirective);

  return { app, router, head };
}
