import { debounce, Deferred } from "@/utils/bouncer";
import type { Cart } from "@paparazzi/types/paparazzi.api.serializers";
import type { CartItem } from "@paparazzi/types/paparazzi.api.serializers.base";
import api from "@virgodev/bazaar/functions/api";
import copy from "@virgodev/bazaar/functions/copy";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import debug from "debug";
import localforage from "localforage";
import { defineStore } from "pinia";
import { useToast } from "primevue/usetoast";
import { computed, nextTick, ref, watch, type Ref } from "vue";
import { useAddressStore } from "./address";
import type { Promotion } from "./defs/shop_defs";
import { useFirebaseStore } from "./firebase";
import { usePromosStore } from "./promos";
import { useShopStore } from "./shop";
import { useSocketStore } from "./socket";
import { useUserStore } from "./user";
import { getOfficialTotals } from "./utils/official_totals";

const log = debug("cart");

const cartStore = localforage.createInstance({
  name: "paparazzi",
  storeName: "cart",
});

export const useCartStore = defineStore("cart", () => {
  const syncDeferred = new Deferred();
  const object: Ref<Cart> = ref(createNewCart());
  const shop = useShopStore();
  const user = useUserStore();
  const firebase = useFirebaseStore();
  const storage = useLocalStorageStore();
  const websocket = useSocketStore();
  const addresses = useAddressStore();
  const toaster = useToast();

  const itemTaxRates = ref(storage.get("taxes:items", {}));
  const shippingTaxRates = ref(storage.get("taxes:shipping", {}));
  const shouldSaveCart = ref(true);
  const cartIdUpdated = ref(0);
  const status = ref("");
  const deferred = new Deferred();

  // globalSyncId
  const totalsTimestamp = ref("");

  const items = computed(() => {
    return (object.value.items || []) as CartItem[];
  });
  const inventorySources = computed<string[]>(() => {
    const userChoicePromos: { [index: number]: any } = {};
    shop.promos
      .filter((p: Promotion) => p.promo_type === "local_userchoice")
      .forEach((p: Promotion) => {
        userChoicePromos[p.id] = true;
      });
    let sources = Array.from(
      items.value
        .filter((i) => !i.promotion || userChoicePromos[i.promotion])
        .map((i) => i.product?.inventory_source || "default")
        .reduce((a, b) => a.add(b), new Set()),
    ) as string[];
    return sources;
  });
  const inventorySource = computed<string>(() => {
    if (inventorySources.value.length === 1) {
      return inventorySources.value[0] || "default";
    } else if (inventorySources.value.length > 1) {
      console.warn("Mixed sources", inventorySources.value);
      return "*mixed*";
    }
    return "default";
  });
  const estimatedTaxes: Ref<number> = computed(() => {
    const itemTaxes = object.value.billing_address?.id
      ? itemTaxRates.value[object.value.billing_address.id] || 0
      : 0;
    const shippingTaxes = object.value.shipping_address?.id
      ? shippingTaxRates.value[object.value.shipping_address.id] || 0
      : 0;
    return (
      subtotal.value * (itemTaxes ? itemTaxes : 0) +
      (object.value.shipping_price || 0) * (shippingTaxes ? shippingTaxes : 0)
    );
  });
  const subtotal = computed(() => {
    return items.value
      .filter((item) => !item.promotion)
      .reduce((a, b) => {
        return a + shop.getPrice(b.product) * b.quantity;
      }, 0);
  });
  const total = computed(() => {
    let shipping: number = 0;
    if (typeof object.value.shipping_price === "string") {
      shipping = parseFloat(object.value.shipping_price) || 0;
    } else {
      shipping = object.value.shipping_price || 0;
    }
    return subtotal.value + estimatedTaxes.value + shipping;
  });
  const volume = computed(() => {
    return items.value
      .filter((item) => !item.promotion)
      .reduce((a, b: CartItem) => {
        return a + parseFloat(`${b.product?.volume || "0"}`) * b.quantity;
      }, 0);
  });
  const count = computed(() => {
    return items.value
      .filter((item) => !item.promotion)
      .reduce((a, b) => {
        return a + (b.quantity || 1);
      }, 0);
  });
  const pvOnlySubtotal = computed(() => {
    let priceType = user.isRep ? "wholesale" : "null";
    const filtered = object.value.items.filter((i) => {
      const product = shop.products.find((p) => p.id === i.product?.id);
      const isStatic = product
        ? shop.staticCategoriesId.indexOf(product.category) > -1
        : false;
      return isStatic || (product?.volume > 0 && !i.promotion);
    });
    return filtered.reduce((a, b) => {
      if (!b.product?.prices) {
        console.error("product is missing prices", b, b.product);
        return a;
      }
      return a + (b.product.prices[priceType] || 0) * b.quantity;
    }, 0);
  });
  const productIds = computed(() => {
    return items.value.map((item) => item.product?.id).filter((a) => a);
  });
  const products = computed(() => {
    return shop.objects.products.filter((product) => {
      return productIds.value.includes(product.id);
    });
  });

  watch(
    products,
    async () => {
      if (shop.isReady) {
        let changedItems = false;
        for (const lineitem of items.value.filter((i) => !i.promotion)) {
          const product = products.value.find(
            (p) => p.id === lineitem.product?.id,
          );
          if (!product || !product.active || product.stock <= 0) {
            lineitem.quantity = 0;
            changedItems = true;
            toaster.add({
              severity: "warn",
              detail: `${lineitem.product?.name} is no longer available and has been removed from your cart`,
              life: 10 * 1000,
            });
          }
        }
        if (changedItems && object.value.items) {
          object.value.items = object.value.items.filter(
            (item) => item.quantity > 0,
          );
          object.value.changed = new Date().toISOString();
        }
      }
    },
    { deep: true },
  );
  watch(
    () => object.value.errors,
    () => {
      if (object.value.items && object.value.errors) {
        for (const oosError of object.value.errors.filter(
          (e) => e.oos && e.products,
        )) {
          if (oosError.products) {
            object.value.items = object.value.items.filter(
              (i) => !oosError.products?.includes(i.product?.id || 0),
            );
            console.warn("removed oos items", oosError.products);
          } else if (oosError.product) {
            object.value.items = object.value.items.filter(
              (i) => oosError.product !== i.product?.id || 0,
            );
            console.warn("removed oos item", oosError.product);
          }
        }
      }
    },
    { deep: true },
  );
  watch(
    () => object.value.changed,
    async () => {
      object.value.totals = undefined;

      if (await debounce("cart-apply-promotions")) {
        const promos = usePromosStore();
        await promos.applyPromotions();
        await saveCart();
        await saveToFirebase();
      }
    },
    { deep: true },
  );
  watch(
    () => user.props.id,
    async () => (object.value = await verify(object.value)),
  );
  watch(
    () => addresses.list.map((i) => i.id),
    () => {
      verifyCartAddressesExist(object.value);
      if (addresses.defaultShipping) {
        if ((object.value.shipping_address?.id ?? -1) === -1) {
          object.value.shipping_address = addresses.defaultShipping;
        }
      }
      if (addresses.defaultBilling) {
        if ((object.value.billing_address?.id ?? -1) === -1) {
          object.value.billing_address = addresses.defaultBilling;
        }
      }
    },
  );
  watch(
    [
      () => object.value.shipping_address?.id,
      () => object.value.billing_address?.id,
    ],
    () => {
      getTaxes();
    },
  );
  watch(
    () => firebase.userData,
    () => {
      if (firebase.shouldUpdate("cart", object.value.changed)) {
        pullFromFirebase();
      }
      syncDeferred.resolve();
    },
  );
  watch(
    () => user.props.id,
    async () => {
      object.value = createNewCart();
      await cartStore.setItem("cart", copy(object.value));
      if (user.props.id) {
        pullFromFirebase();
      }
      console.warn("user changed, clearing cart", user.props.id, object.value);
    },
  );

  function createNewCart(): Cart {
    return {
      items: [],
      count: 0,
    };
  }

  async function verify(cart: Cart): Promise<Cart> {
    // remove address that could be from another account
    await addresses.ready;
    if (
      cart.shipping_address?.owner &&
      cart.shipping_address?.owner !== user.props.id
    ) {
      console.warn("address unset, owner mismatch");
      cart.shipping_address = undefined;
    }
    if (
      cart.billing_address?.owner &&
      cart.billing_address?.owner !== user.props.id
    ) {
      cart.billing_address = undefined;
    }

    // add in default addresses
    if (!cart.shipping_address && addresses.defaultShipping) {
      cart.shipping_address = addresses.defaultShipping;
    }
    if (!cart.billing_address && addresses.defaultBilling) {
      cart.billing_address = addresses.defaultBilling;
    }

    if (cart.shipping_price) {
      if (typeof cart.shipping_price === "string") {
        cart.shipping_price = parseFloat(cart.shipping_price);
      }
    }

    await verifyCartAddressesExist(cart);
    return cart;
  }

  async function verifyCartAddressesExist(cart: Cart) {
    await addresses.ready;
    const ids = addresses.list.map((i) => i.id);
    if (!ids.includes(cart.shipping_address?.id)) {
      console.warn(
        "unset address, not in my list of addresses",
        cart.shipping_address?.id,
        ids,
      );
      cart.shipping_address = undefined;
    }
    if (!ids.includes(cart.billing_address?.id)) {
      cart.billing_address = undefined;
    }
  }

  async function saveCart() {
    if (shouldSaveCart.value && (await debounce("cart-save"))) {
      // cart.user = globalUserId;

      await cartStore.setItem("cart", copy(object.value));

      // TODO: update other tabs via local storage
      // save("cartIdb", { id: cart.id, modified: Date.now() });
    }
  }

  function shouldUpdate(newCart: Cart, oldCart: Cart) {
    let retval = false;
    if (
      (oldCart.id && newCart.id && oldCart.id < newCart.id) ||
      (newCart.id && !oldCart.id) ||
      (newCart.changed && !oldCart.changed)
    ) {
      retval = true;
    } else if (
      newCart.changed &&
      oldCart.changed &&
      newCart.changed >= oldCart.changed
    ) {
      let d1 = new Date(newCart.changed);
      let d2 = new Date(oldCart.changed);
      retval = d1 > d2;
    }
    return retval;
  }

  async function setup(): Promise<void> {
    // TODO: store.dispatch("startupWishlist", socket);
    websocket.on("cart", async (data: Cart) => {
      // TODO:
      // if (cartListener) {
      //   cartListener(data);
      // } else
      if (shouldUpdate(data, object.value)) {
        log("got cart", data);
        object.value = await verify(data);
        status.value = "";
      } else {
        status.value = "";
        log("ignoring cart update");
      }
    });

    const saved: Cart | null = await cartStore.getItem("cart");
    if (saved) {
      shouldSaveCart.value = false;
      object.value = await verify(saved);
      await nextTick();

      shouldSaveCart.value = true;
    }

    getTaxes();
    deferred.resolve();
  }

  async function getCartId(
    opts = { force: false } as {
      force?: boolean;
    },
  ) {
    if (user.props.id) {
      let expired = false;
      if (
        !cartIdUpdated.value ||
        cartIdUpdated.value < Date.now() - 1000 * 60 * 60 * 8
      ) {
        log("get card id, as it is stale");
        expired = true;
      }
      if (!object.value.id || expired || opts.force) {
        const response = await api({
          url: "cart/cart_id/",
          json: { check: opts.force || false },
          method: "POST",
        });
        log("getting cart id", response);
        if (response.ok && response.body.id) {
          log("got cart id", response.body.id);
          object.value.id = response.body.id;
          object.value.changed = new Date().toISOString();
          cartIdUpdated.value = Date.now();
        } else {
          console.error("failed to get cart id", response);
        }
      }
    }
  }

  async function pullFromFirebase() {
    await shop.ready;
    const cart = firebase.mirrors.find((m) => m.name === "user")?.data?.cart;
    if (cart) {
      const items = [];
      for (const item of cart.items || []) {
        let product = shop.objects.products.find(
          (p) => p.slug === item.product,
        );
        if (product) {
          item.product = product;
          items.push(item);
        } else {
          console.log("firebase missing product", item.product);
        }
      }
      cart.items = items;
      object.value = cart;
    }
  }

  function saveToFirebase() {
    const cart = copy(object.value);
    cart.items = cart.items.map((i: any) => {
      let item = copy(i);
      item.product = item.product.slug;
      return item;
    });
    cart.error_messages = [];
    delete cart.errors;
    delete cart.checkout;
    firebase.update("cart", cart.changed, cart);
  }

  async function empty() {
    object.value.items = [];
    object.value.changed = new Date().toISOString();
  }

  async function clear() {
    object.value = {
      changed: "",
      items: [] as CartItem[],
    };
    await cartStore.setItem("cart", copy(object.value));
  }

  async function sync(check = false) {
    const promos = usePromosStore();
    await promos.applyPromotions();

    // clear cart server data
    object.value.totals = undefined;
    object.value.error_messages = null;

    if (!check) {
      await getCartId();
    } else {
      await getOfficialTotals();
      return true;
    }
    return false;
  }

  async function getTaxes() {
    const shipping = object.value.shipping_address?.id;
    const billing = object.value.billing_address?.id;

    if (billing && shipping) {
      if (!itemTaxRates.value[billing] || !shippingTaxRates.value[shipping]) {
        const response = await api({
          url: "cart/taxes/",
          params: { shipping, billing },
          method: "GET",
        });
        if (response.ok) {
          itemTaxRates.value = storage.get("taxes:items", {});
          itemTaxRates.value[billing] = response.body.items;
          storage.put("taxes:items", itemTaxRates.value);

          shippingTaxRates.value = storage.get("taxes:shipping", {});
          shippingTaxRates.value[shipping] = response.body.shipping;
          storage.put("taxes:shipping", shippingTaxRates.value);
        }
      }
    }
  }

  return {
    promises: {
      sync: syncDeferred.promise,
    },
    object,
    items,
    count,
    subtotal,
    pvOnlySubtotal,
    itemTaxRates,
    shippingTaxRates,
    estimatedTaxes,
    total,
    volume,
    inventorySource,
    status,
    ready: deferred.promise,
    setup,
    getCartId,
    sync,
    empty,
    clear,
  };
});
