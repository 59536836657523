export function nextFrame(value) {
  return new Promise(function (resolve) {
    if (typeof fakeRequestAnimationFrame !== "undefined") {
      return fakeRequestAnimationFrame(function () {
        resolve();
      });
    } else {
      window.requestAnimationFrame(function () {
        resolve(value);
      });
    }
  });
}

export function timer(ms, value) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      if (value instanceof Error) {
        reject(value);
      } else {
        resolve(value);
      }
    }, ms);
  });
}

export class Async {
  constructor() {
    this.promise = null;
    this.reject = null;
    this.resolve = null;
  }

  start() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    return this.promise;
  }
}

export class Timer extends Async {
  constructor(ms) {
    super();
    this.duration = ms;
  }

  stop() {
    clearTimeout(this.__timer);
    if (this.reject) {
      this.reject(new Error("Canceled timer"));
      this.reject = null;
    }
  }

  start() {
    super.start();
    this.__timer = setTimeout(this.resolve, this.duration);
    return this.promise;
  }

  reset() {
    this.stop();
    this.start();
  }
}
