<template>
  <svg
    width="32"
    height="30"
    viewBox="0 0 32 30"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-for="path in option.paths"
      :d="path"
      stroke="currentColor"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { computed, ref } from "vue";

const props = withDefaults(defineProps<{
  name: string;
  strokeWidth?: number;
  fill?: string;
}>(), {
  strokeWidth: 0.75,
  fill: 'none'
});

interface VectorImage {
  paths: string[];
}

const options:Ref<{[index:string]: VectorImage}> = ref({
  profile: {
    paths: [
      "M15.6299 14.92C17.712 14.92 19.3999 13.2321 19.3999 11.15C19.3999 9.06789 17.712 7.38 15.6299 7.38C13.5477 7.38 11.8599 9.06789 11.8599 11.15C11.8599 13.2321 13.5477 14.92 15.6299 14.92Z",
      "M8.37988 22.38C8.37988 18.38 11.6299 15.13 15.6299 15.13C19.6299 15.13 22.8799 18.38 22.8799 22.38",
    ],
  },
  bag: {
    paths: [
      "M21.8701 22.38H9.14009C8.68009 22.38 8.32009 21.97 8.38009 21.52L9.67009 11.55C9.72009 11.17 10.0401 10.88 10.4301 10.88H20.5801C20.9601 10.88 21.2901 11.17 21.3401 11.55L22.6301 21.51C22.6901 21.97 22.3301 22.37 21.8701 22.37V22.38Z",
      "M12.0898 10.79C12.0898 8.91 13.6198 7.38 15.4998 7.38C17.3798 7.38 18.9098 8.91 18.9098 10.79",
    ]
  },
  search: {
    paths: [
      "M14.2501 19.13C17.4976 19.13 20.1301 16.4974 20.1301 13.25C20.1301 10.0026 17.4976 7.37 14.2501 7.37C11.0027 7.37 8.37012 10.0026 8.37012 13.25C8.37012 16.4974 11.0027 19.13 14.2501 19.13Z",
      "M18.48 17.45L23.4 22.38",
    ],
  },
  wishlist: {
    paths: [
      "M15.5231 9.0431C10.1031 3.6231 3.6231 10.1031 9.0431 15.5231L15.5231 22.0031L22.0031 15.5231C27.3931 10.1331 20.9131 3.6631 15.5231 9.0431Z",
    ],
  },
});
const option = computed(() => {
  return options.value[props.name] || { paths: [] };
});
</script>

<style scoped>
svg {
  stroke-width: 0.75;
}
svg:hover {
  stroke-width: 1.5;
}
</style>
