
const CONVERSION_TABLE = [
  ["\\~/tp/", "https://vw-paparazzi\\.storage.googleapis\\.com/thumbnails/products/"],
  ["\\~/p/", "https://vw-paparazzi\\.storage.googleapis\\.com/products/"],
  ["\\[c$1\\]", "\\.$1x$1_q85_crop_upscale\\.jpg"],
  ["\\[w$1\\]", "\\.$1x$1_q85\\.jpg"],
  ["\\[xxs\\]", "\\.240.0x240.0_q85\\.jpg"],
  ["\\[xs\\]", "\\.960x960_q85\\.jpg"],
];
const FORWARD_CONVERSION_TABLE = CONVERSION_TABLE.map(
  ([a, b]) => [new RegExp(a.replace(/\$1/g, '(\\d+)')), b.replace(/\\/g, '')],
);
const REVERSE_CONVERSION_TABLE = CONVERSION_TABLE.map(
  ([a, b]) => [new RegExp(b.replace(/\$1/g, '(\\d+)')), a.replace(/\\/g, '')],
);

export function alterAllThumbs(data, alterations, objectId=null) {
  for (let product of data) {
    if (product.images) {
      product.images = alterThumbs(product.images, alterations, objectId);
    }
    if (product.style_image) {
      product.style_image = alterThumbs([product.style_image], alterations, objectId)[0];
    }
  }
  return data;
}

export function alterThumbs(data, alterations, objectId=null) {
  for (let thumb of data) {
    for (let key in thumb) {
      thumb[key] = alterThumb(thumb[key], alterations, objectId);
    }
  }
  return data;
}

export function alterThumb(orig, alterations, objectId=null) {
  let thumb = orig || '';
  if (!thumb) {
    console.error('thumb is missing', thumb, orig, objectId);
  }
  for (let [name, value] of alterations) {
    thumb = thumb.replace(name, value);
  }
  return thumb;
}

export function condenseThumbs(data, objectId=null) {
  alterAllThumbs(data, REVERSE_CONVERSION_TABLE, objectId);
  return data;
}


export function expandThumb(image, objectId=null) {
  return alterThumb(image, FORWARD_CONVERSION_TABLE, objectId);
}


export function expandThumbs(data, objectId=null) {
  alterAllThumbs(data, FORWARD_CONVERSION_TABLE, objectId);
  return data;
}