import debug from "debug";
import type { Auth } from "firebase/auth";
import {
  type Database,
  ref as fireRef,
  runTransaction,
} from "firebase/database";

const log = debug("lock");

export async function acquireSemaphore(
  db: Database,
  auth: Auth,
  key: string,
  value: string,
): Promise<boolean> {
  if (auth.currentUser) {
    log("acquiring lock", `users/${auth.currentUser.uid}/locks/${key}`, value);
    const fvalue = fireRef(db, `users/${auth.currentUser.uid}/locks/${key}`);
    const result = await runTransaction(fvalue, async (current) => {
      log("lock acquired", key, current);
      const value = current || { timestamp: 0 };
      const now = Date.now();
      const cutoff = now - 60 * 1000;
      if (value.timestamp < cutoff) {
        value.timestamp = now;
        return value;
      }
    });
    return result.committed;
  }
  return false;
}

export async function releaseSemaphore(
  db: Database,
  auth: Auth,
  key: string,
  value: string,
): Promise<boolean> {
  if (auth.currentUser) {
    const fvalue = fireRef(db, `users/${auth.currentUser.uid}/locks/${key}`);
    const result = await runTransaction(fvalue, async (current) => {
      const cutoff = Date.now() - 60 * 1000;
      const value = current || { timestamp: 0 };
      if (value.timestamp < cutoff) {
        return false;
      } else {
        return;
      }
    });
    return result.committed;
  }
  return false;
}

export async function acquireLock(
  db: Database,
  auth: Auth,
  key: string,
  value: string,
  func: Function,
): Promise<any> {
  let err = null;
  let locked = false;
  let result = null;
  try {
    locked = await acquireSemaphore(db, auth, key, value);
    log("lock", locked);
    if (locked) {
      result = await func();
      return result;
    }
    throw new Error("failed to acquire semaphore");
  } catch (ex) {
    err = ex;
  }
  if (err) {
    throw err;
  }
  if (locked) {
    await releaseSemaphore(db, auth, key, value);
  }
  return result;
}
