<template>
  <div class="shop-status" @click="handleShow">
    <img v-if="!ready" :src="Spinner" sizes="23px" />
    <div v-else class="empty" />
    <OverlayPanel ref="status">
      <div class="grid2 m-xs gap-2xs">
        <template v-for="item of items">
          <span>{{ item.label }}</span>
          <span v-if="item.finished" class="duration">
            {{ item.duration }}s
          </span>
          <span v-else class="duration"> ... </span>
        </template>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup lang="ts">
import Spinner from "@/assets/img/loading.gif";
import { useCartStore } from "@/stores/cart";
import { useFirebaseStore } from "@/stores/firebase";
import { useShopStore } from "@/stores/shop";
import { useUserStore } from "@/stores/user";
import OverlayPanel from "primevue/overlaypanel";
import { onMounted, ref, watch } from "vue";

interface StatusItem {
  id: string;
  group: string;
  label: string;
  promise: Promise<any>;
  duration?: number;
  finished?: Date;
}

const shop = useShopStore();
const firebase = useFirebaseStore();
const cart = useCartStore();
const user = useUserStore();

const status = ref<OverlayPanel>();
const items = ref<StatusItem[]>([
  {
    id: "product-cache",
    group: "product",
    label: "Loading product cache",
    promise: shop.promises.uncached,
  },
  {
    id: "product-sync",
    group: "product",
    label: "Syncing products",
    promise: shop.ready(),
  },
  {
    id: "firebase-login",
    group: "cart",
    label: "Logging in",
    promise: firebase.promises.login,
  },
  {
    id: "cart-sync",
    group: "cart",
    label: "Cart synced",
    promise: cart.promises.sync,
  },
]);
const ready = ref(false);

watch(
  () => user.props.id,
  () => {
    for (const item of items.value.filter((i) =>
      ["firebase-login", "cart-sync"].includes(i.id),
    )) {
      item.finished = undefined;
    }
    resolveTimes();
  },
);

onMounted(async () => {
  await resolveTimes();
});

function handleShow(e: MouseEvent) {
  if (status.value) {
    status.value.show(e);
  }
}

async function resolveTimes() {
  if (user.props.id) {
    const started = Date.now();
    const promises: Promise<any>[] = [];
    ready.value = false;
    items.value
      .filter((i) => !i.finished)
      .forEach((item) => {
        promises.push(item.promise);
        item.promise.then(() => {
          item.duration = Math.floor((Date.now() - started) / 100);
          item.finished = new Date();
        });
      });
    await Promise.all(promises);
  }
  ready.value = true;
}
</script>

<style scoped>
.shop-status {
  display: inline;
}
img,
.empty {
  margin-top: 4px;
  width: 23px;
  height: 23px;
}
.duration {
}
</style>
