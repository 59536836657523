<template>
  <input
    @input="handleInput($event.target?.value)"
    @blur="handleBlur()"
    :value="props.modelValue"
    :name="props.name"
    :required="props.required === undefined ? true : props.required"
    maxlength="16"
    minlength="8"
    pattern="[\dX]+"
    type="text"
    autocomplete="cc-number"
    class="p-inputtext _lr-hide"
    ref="el"
  />
</template>

<script setup lang="ts">
import cardValidator from "card-validator";
import { onMounted, ref, watch } from "vue";

const props = defineProps<{
  modelValue: string;
  name: string;
  required?: boolean;
}>();
const emits = defineEmits(["update:modelValue"]);

const cardType = ref("");
const isValid = ref(false);
const initial = ref("");
const el = ref<HTMLInputElement>();

watch(cardType, () => {
  let ct =
    {
      "american-express": "amex",
      "master-card": "mastercard",
    }[cardType.value] || cardType.value;
});

onMounted(() => {
  initial.value = props.modelValue;
});

function validate(partial?: boolean): void {
  if (el.value) {
    el.value.setCustomValidity("");
  }
  let valid = false;

  // allow masked value
  if (
    /^x{4}\d+/i.test(props.modelValue) &&
    props.modelValue === initial.value
  ) {
    valid = true;
  } else if (props.modelValue) {
    const verification = cardValidator.number(props.modelValue);

    if (
      (partial && verification.isPotentiallyValid && verification.card) ||
      (!partial && verification.isValid)
    ) {
      valid = true;
    }
  }

  isValid.value = valid;

  if (!partial && el.value && !isValid.value) {
    el.value.setCustomValidity("Please type a valid credit card number");
  }
}

function handleInput(value: string) {
  emits("update:modelValue", value);
  validate(true);
}

function handleBlur() {
  validate();
}
</script>
