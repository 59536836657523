<template>
  <Dialog
    class="signin-dialog"
    v-model:visible="visible"
    dismissable-mask
    modal
    :show-header="false"
  >
    <SigninForm @login="visible = false" />
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import { computed } from "vue";
import SigninForm from "./SigninForm.vue";

const props = defineProps<{ visible: boolean }>();
const emits = defineEmits(["update:visible"]);

const visible = computed({
  get: () => props.visible,
  set: (v) => emits("update:visible", v),
});
</script>

<style scoped></style>
