import { useUserStore } from "@/stores/user";
import { Deferred } from "@/utils/bouncer";
import debug from "debug";
import Socket from "paparazzi/utils/websocket";
import { defineStore } from "pinia";
import { ref, shallowRef, watch, type Ref } from "vue";

const log = debug("socket");

export type Packet = [string, any];

export const useSocketStore = defineStore("socket", () => {
  let endpoint = `/websocket/?app=1`;
  let domain = location.hostname;
  let host = import.meta.env.VITE_APP_API_URL;
  if (host) {
    domain = host.split("//")[1].split("/")[0];
  }
  if (/http:/.test(host)) {
    endpoint = `ws://${domain}${endpoint}`;
  } else {
    endpoint = `wss://${domain}${endpoint}`;
  }

  const socket: Ref<Socket> = shallowRef(new Socket(endpoint));
  const user = useUserStore();
  const isLoggedIn = ref<boolean>(false);
  const loginExpires = ref<number>(0);
  let wsLoggedIn = new Deferred();

  let __onConnected = -1;

  watch(() => user.props.token, login);

  async function login() {
    // console.log("user token changed", user.props.token);
    fetchUserData();
    await socket.value.wait();
  }

  async function setup() {
    socket.value.on("_message_", (packet: Packet) => {
      log(packet[0], packet[1]);
    });

    socket.value.on("connect", (data: any) => {
      log("connected");
      if (user.props.token) {
        clearTimeout(__onConnected);
        __onConnected = window.setTimeout(
          () => {
            fetchUserData();
          },
          100 + Math.random() * 150,
        );
      }
    });

    // if (user.props.token) {
    //   await login();
    // }

    // TODO:
    // socket.on('wishlist', (data) => {
    //   $store.dispatch('wishlistReplace', data.wishlist);
    // });

    socket.value.on("user", async (data: any) => {
      // TODO:
      // checkUpdates();
      log("user update", data.username, data.rep_number);

      await user.update(data);

      wsLoggedIn.resolve();

      isLoggedIn.value = true;
      loginExpires.value = Date.now() + 1000 * 60 * 60;

      // TODO:
      // await $store.dispatch("syncShop", $root);
      // await $store.dispatch("startupWishlist", socket);
      // $store.dispatch("staleCartCheck");
    });
  }

  async function emit(event: string, data: any = {}) {
    log(`%c<= ${event}`, "font-weight: bold; color: darkgreen", data);
    if (user.props.token) {
      data.auth_token = user.props.token;
      data.csrftoken = user.csrf;
    }
    socket.value.emit(event, data);
  }

  async function fetchUserData() {
    wsLoggedIn.reset();

    const response = await emit("user", {
      app: true,
      auth_token: user.props.token,
      hash: window.versionHash,
      location: document.location.hash,
    });
    // console.log("ws login", user.props.token, response);

    // TODO
    // check that they can login even without ws
    // const response = await Promise.race([
    //   wsLoggedIn,
    //   new Promise((r) => setTimeout(r, 10000)),
    // ]);
    // if (response) {
    //   let me = null;
    //   try {
    //     const response = await api("profile/my");
    //     me = response.id;
    //   } catch (ex) {
    //     // pass
    //   }
    //   if (me) {
    //     this.wsLoggedInResolve(true);
    //     this.isLoggedIn = true;
    //   } else {
    //     this.$router.push("/login/");
    //   }
    // }

    // TODO
    // redirect anons
    // if (this.user.anon) {
    //   this.$store.commit("userUpdate", { token: null });
    //   if (this.$route.path != "/login/") {
    //     await this.$nextTick();
    //     this.$router.push("/login/");
    //   }
    // }

    // cleanup
  }

  function wait(timeout: number) {
    return socket.value.wait(timeout);
  }

  function on(event: string, callback: Function) {
    return socket.value.on(event, callback);
  }

  function off(event: string, callback: Function) {
    socket.value.off(event, callback);
  }

  function once(event: string, callback: Function, msgId?: string) {
    return socket.value.once(event, callback, msgId);
  }

  function isConnected(timeout: number) {
    return socket.value.isConnected(timeout);
  }

  return {
    socket,
    isLoggedIn,
    on,
    off,
    once,
    wait,
    isConnected,
    emit,
    setup,
    isLoggedInPromise: wsLoggedIn.promise,
  };
});
