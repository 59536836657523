<template>
  <form @submit.prevent="attemptLogin">
    <h1>
      Site Administration v1.003 - {{ attempts }} -
      {{ Math.floor((expires - timesync.now) / 100) }}
    </h1>
    <div v-if="attempts > 0">
      <span v-if="error" />
      <b v-if="error">Error: {{ error }}</b>
      <label>Username</label>
      <input type="username" v-model="username" required />
      <label>Password</label>
      <input type="password" v-model="password" required />
      <span />
      <div><button>Login</button></div>
    </div>
    <div v-else>
      <b>You are locked out</b>
    </div>
  </form>
</template>

<script setup lang="ts">
import { useTimesyncStore } from "@/stores/timesync";
import { useSiteLockStore } from "@/utils/site_lock";
import { nextTick, ref } from "vue";

const expires = ref(Date.now() + 1000 * 60 * 60 * 2);
const attempts = ref(5);
const sitelock = useSiteLockStore();
const timesync = useTimesyncStore();

const error = ref("");
const username = ref("");
const password = ref("");

async function attemptLogin() {
  attempts.value -= 1;
  if (attempts.value > 0) {
    error.value = "";
    if (username.value.length === 6) {
      sitelock.password = password.value;
      await nextTick();
    }

    if (username.value.length !== 6 || !sitelock.permitted) {
      error.value = `Incorrect username or password. This login attempt has been recorded and your IP address has been logged ${Date.now()}`;
    }
  } else {
    error.value = `You have exceeded your attempts - ${Date.now()}`;
  }
}
</script>

<style scoped>
form {
  font-family: "Courier New", Courier, monospace;
  display: flex;
  flex-direction: column;
  gap: 1em;
  background: lightseagreen;
  width: 100vw;
  height: 100vh;
}
form b {
  display: block;
  padding: 1em;
}
form > h1 {
  background: lightgreen;
  padding: 1em;
}
form > div {
  display: grid;
  align-items: center;
  max-width: 350px;
  gap: 0.5em;
  grid-template-columns: auto auto;
  margin: 2em;
  margin-top: 0;
}
</style>
