<template>
  <div class="m-lg-y">
    <template v-if="passwordReset">
      <div class="thanks flex-column flex-center flex-align-center gap-2xs">
        <h2 class="pa-h2 pa-pink">Thank you</h2>
        <div class="pa-p-small text-center">
          An email has been sent to reset your password.
        </div>
      </div>
    </template>
    <template v-else-if="hasForgotten">
      <div class="flex-column gap-2xs">
        <h2 class="pa-h2">Reset Password</h2>
        <div class="pa-p-small text-center">
          Enter the email address associated with your account.
        </div>
        <div class="pa-p-small text-center">
          Click
          <a
            href="https://tools.securefreedom.com/Paparazzi/Account/LogOn"
            class="pa-pink"
          >
            here
          </a>
          if you are a Consultant.
        </div>
      </div>
      <div class="login">
        <PapaAutoForm
          v-model="object"
          @submit="handleReset"
          :errors="forgotErrors"
          :fields="forgotFields"
        >
          <template #default>
            <div class="flex-row flex-center buttons">
              <Button type="submit" class="grey">Reset Password</Button>
              <Button @click="hasForgotten = false" class="grey">Cancel</Button>
            </div>
          </template>
        </PapaAutoForm>
      </div>
    </template>
    <template v-else>
      <h2>Sign In</h2>
      <div class="login">
        <PapaAutoForm
          v-model="object"
          @submit="handleSubmit"
          :errors="errors"
          :fields="fields"
        >
          <template #default>
            <div class="flex-row flex-center buttons">
              <Button
                v-if="status"
                type="submit"
                class="grey disabled"
                disabled
              >
                <img :src="Spinner" width="20" height="20" /> Signing In...
              </Button>
              <Button v-else type="submit" class="grey">Sign In</Button>

              <router-link :to="{ name: 'accounts-signup' }">
                <Button label="Create Account" class="grey" />
              </router-link>
            </div>
            <div class="forgotten flex-row flex-center padded">
              <a href="#" @click.prevent="hasForgotten = true">
                Forgot Password?
              </a>
            </div>
          </template>
        </PapaAutoForm>
      </div>
    </template>
  </div>
</template>

<route lang="json">
{ "name": "accounts-signin" }
</route>

<script setup lang="ts">
import type { FieldDef } from "@virgodev/vue-models/components/forms/defs";
import type { Ref } from "vue";

import Spinner from "@/assets/img/loading.gif";
import PapaAutoForm from "@/components/PapaAutoForm.vue";
import { useSocketStore } from "@/stores/socket";
import { useUserStore } from "@/stores/user";
import Button from "primevue/button";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const user = useUserStore();
const socket = useSocketStore();
const emits = defineEmits(["login"]);

const object: Ref<Record<string, string>> = ref({});
const forgotErrors = ref<Record<string, string[]>>({});
const errors = ref<Record<string, string[]>>({});
const hasForgotten = ref(false);
const passwordReset = ref(false);
const status = ref("");

const fields: Ref<FieldDef[]> = computed(() => {
  return [
    { name: "username", label: "Username", autocomplete: "username" },
    {
      name: "password",
      label: "Password",
      type: "password",
      autocomplete: "current-password",
    },
  ];
});

const forgotFields: Ref<FieldDef[]> = computed(() => {
  return [{ name: "email", label: "Email Address", autocomplete: "email" }];
});

async function handleSubmit() {
  status.value = "loading";
  const response = await user.login(
    object.value.username,
    object.value.password,
  );

  if (!response) {
    const route = router.currentRoute.value;
    if (route.query.back) {
      router.push(route.query.back as string);
    } else if (route.query.next) {
      router.push(route.query.next as string);
    } else {
      router.push({ name: "home" });
    }
    emits("login");
  } else {
    errors.value = { __all__: [response] };
    status.value = "";
  }
}

async function handleReset() {
  const body = new URLSearchParams();
  body.append("email", object.value.email);
  body.append("user_type", "customer");
  // body.append("csrfmiddlewaretoken", csrf);

  forgotErrors.value = {};
  const response = await fetch(
    "https://paparazziaccessories.com/accounts/password/reset/",
    // "http://localhost:8000/accounts/password/reset/",
    {
      credentials: "include",
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "X-CSRFToken": user.csrf,
      },
      body,
    },
  );
  if (response.status === 200) {
    passwordReset.value = true;
  } else {
    forgotErrors.value["email"] = ["Failed to reset password, try again later"];
  }
}
</script>

<style scoped>
.login {
  display: flex;
  justify-content: center;
}
.auto-form :deep(.inside) {
  width: 356px;
  grid-template-columns: auto;
  gap: 12px !important;
}

.auto-form :deep(.inside label),
.auto-form :deep(.buttons) {
  padding: 0;
  margin-top: var(--Lg, 48px);
}
.auto-form :deep(.buttons) {
  gap: var(--Sm);
}

.forgotten {
  color: #000;
  text-align: center;

  /* Small Print */
  font-family: Indivisible;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.thanks {
  height: 361px;
}

.p-message-wrapper {
  padding: 0;
}

@media screen and (max-width: 500px) {
  .auto-form :deep(.inside) {
    width: 100%;
  }
}
</style>
