const DEFAULT_TIMEOUT = import.meta.env.VITEST ? 100 : 1000;

export class Bouncer {
  resolve: Function | null;
  timeout: number;
  promise: Promise<boolean> | null;
  _timeout: number = -1;

  constructor(timeout = DEFAULT_TIMEOUT) {
    this.resolve = null;
    this.timeout = import.meta.env.VITEST ? 100 : timeout;
    this.promise = null;
  }

  async debounce(): Promise<boolean> {
    this.cancel();
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      clearTimeout(this._timeout);
      this._timeout = window.setTimeout(() => {
        resolve(true);
      }, this.timeout);
    });
    return this.promise;
  }

  cancel() {
    if (this.resolve) {
      clearTimeout(this._timeout);
      this.resolve(false);
      this.promise = null;
    }
  }
}

const bouncers: Record<string, Bouncer> = {};
export function debounce(
  name: string,
  timeout: number = DEFAULT_TIMEOUT,
): Promise<boolean> {
  if (!bouncers[name]) {
    bouncers[name] = new Bouncer(timeout);
  }
  return bouncers[name].debounce();
}

export function cancelDebounce(name: string) {
  if (bouncers[name]) {
    bouncers[name].cancel();
  }
}

export class Deferred<T = void> {
  _resolve: ((a: T) => void) | null = null;
  _reject: ((error: Error) => void) | null = null;
  promise: Promise<T>;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
  get resolved(): boolean {
    return !!this.resolve;
  }
  reset() {
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
  resolve(a: T): void {
    if (this._resolve) {
      this._resolve(a);
    }
  }
  reject(e: Error): void {
    if (this._reject) {
      this._reject(e);
    }
  }
}
