import { useFirebaseStore } from "@/stores/firebase";
import { useSocketStore } from "@/stores/socket";
import { useUserStore } from "@/stores/user";
import type {
  ApiError,
  ApiSuccess,
  HandlerParamsBase,
} from "@virgodev/bazaar/functions/api";
import api from "@virgodev/bazaar/functions/api";
import copy from "@virgodev/bazaar/functions/copy";
import debug from "debug";
import { SocketStatus } from "paparazzi/utils/websocket";

const log = debug("api");
const WS_FORMAT = "color: darkgreen; font-weight: bold";

export async function uapi(
  request: HandlerParamsBase,
): Promise<ApiError | ApiSuccess> {
  const socket = useSocketStore();
  log(`[uapi] ${request.url}`);
  if (
    import.meta.env.NODE_ENV !== "test" &&
    localStorage.websockets !== "disabled"
  ) {
    try {
      const status = await socket.wait(request.options?.timeout || 30000);
      if (status.ok) {
        try {
          return await wsapi(request, request.options?.timeout);
        } catch (ex) {
          console.error("- no wsapi -", ex);
        }
      } else {
        console.warn("socket still waiting...");
      }
    } catch (ex) {
      console.log("error???", ex);
    }
  }
  return await api(request);
}

let msgid = 0;
export async function wsapi(
  request: HandlerParamsBase,
  timeout: number = 30000,
  attempts: number = 1,
): Promise<ApiError | ApiSuccess> {
  const socket = useSocketStore();
  const user = useUserStore();
  const firebase = useFirebaseStore();

  if (localStorage.websockets === "disabled") {
    throw new Error("websockets disabled");
  }
  let start = Date.now();
  let result = null;
  let url = request.url.replace(/\//g, "_");
  url = url.replace(/^_|_$/g, "");

  log(`[wsapi] ${url}`);
  const data = request.params || request.json || {};
  while (attempts > 0) {
    attempts -= 1;

    try {
      result = await Promise.race([
        new Promise((resolve) => {
          msgid += 1;
          const mid = `${user.props.id}:${firebase.clientId}:${msgid}`;
          socket.once(
            url,
            (d: Record<string, any>) => {
              log(`%c[ws] ${url} ${Date.now() - start}ms`, WS_FORMAT);
              resolve(d);
            },
            mid,
          );
          if (localStorage.websockets === "broken") {
            console.warn("websockets are disabled", url, data);
          } else {
            const content = copy(data || {});
            content.msgid = mid;
            socket.emit(url, content);
          }
        }),
        socket.isConnected(timeout),
      ]);
      if (result instanceof SocketStatus) {
        throw result.error;
      }
      break;
    } catch (ex) {
      log(`%c[ws] ${url} ${ex}`, WS_FORMAT, data);
      // if we are disconnected give up
      // if (ex.code === "disconnected") {
      //   attempts = 0;
      // }
      if (attempts === 0) {
        throw ex;
      }
    }
  }
  if (result) {
    return {
      body: result,
      status: 200,
      ok: true,
    };
  }
  return result;
}
