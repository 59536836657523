import api from "@virgodev/bazaar/functions/api";
import { defineStore } from "pinia";
import { ref, type Ref } from "vue";

export const useTimesyncStore = defineStore("timesync", () => {
  const promise: Ref<Promise<number> | null> = ref(null);
  const lastAttempt = ref(0);
  const offset = ref(5 * 60 * 1000);
  const now = ref(Date.now());

  function calculate(force = false) {
    if (force || Date.now() - lastAttempt.value > 1000 * 60 * 60 * 2) {
      lastAttempt.value = Date.now();
      promise.value = (async () => {
        let start = Date.now(); // - 1000 * 60 * 60;
        let servertime = null;
        let response = await api({ url: "profile/time/" });
        if (response.ok) {
          servertime = response.body.time * 1000;
        }
        if (servertime) {
          let end = Date.now(); // - 1000 * 60 * 60;
          let trip = Math.max(0, Math.min(30000, end - start));
          const retval = servertime - end + trip / 2;
          // console.log("time offset", retval / 1000, "s");
          offset.value = retval;
          return retval;
        }
        return NaN;
      })();
    }
    return promise.value;
  }

  setInterval(() => {
    now.value = Date.now();
  }, 500);

  return { now, offset, calculate };
});
