import { useSocketStore } from "@/stores/socket";
import { timer } from "@paparazzi/utils/promise";
import { uapi } from "./api";

export async function getCeleryResult<T = any>({
  task,
  checkTimeout = 1000 * 5,
  maxTimeout = 1000 * 120,
  dropExceptions = true,
}: {
  task: string;
  checkTimeout?: number;
  maxTimeout?: number;
  dropExceptions?: boolean;
}): Promise<T> {
  const websocket = useSocketStore();

  if (!task) {
    console.log("invalid task id", task);
    throw new Error(`invalid task_id [${task}]`);
  }

  let stop = false;
  const wsFunction = (resolve: Function) => {
    return (data: any) => {
      console.log("ws function", data);
      if (data.task === task) {
        resolve(data);
      }
    };
  };

  const tooLate = timer(maxTimeout, new Error("timeout"));
  const wsResult = new Promise((resolve) => {
    websocket.on("task", wsFunction(resolve));
  });
  const httpResult = (async () => {
    while (!stop) {
      await timer(checkTimeout);
      if (!stop) {
        const response = await uapi({
          url: "cart/task",
          json: { task },
          method: "POST",
        });
        console.log("check response", response);
        if (
          response.ok &&
          ["REVOKED", "FAILED", "SUCCESS"].includes(response.body.status)
        ) {
          return response.body.result as any;
        } else if (!dropExceptions) {
          throw new Error(response.body);
        }
      }
    }
    return null;
  })();

  const result = await Promise.race([tooLate, wsResult, httpResult]);
  console.log("result", result);
  stop = true;
  websocket.off("task", wsFunction);

  return result;
}
