import { defineStore } from "pinia";
import { computed, ref, type Ref } from "vue";

export const useSSRStore = defineStore("ssr", () => {
  const needs: Ref<{ [key: string]: boolean }> = ref({});
  const ready = computed(() => {
    const values = Object.values(needs.value);
    const waiting = values.some((item) => item === false);
    // console.log("ready?", !waiting, values);
    return !waiting;
  });
  function requires(value: string) {
    if (!needs.value[value]) {
      needs.value[value] = false;
    }
  }
  function provides(value: string) {
    needs.value[value] = true;
  }
  return { needs, ready, requires, provides };
});
