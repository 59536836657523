import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

export const useSiteLockStore = defineStore("site-lock", () => {
  const storage = useLocalStorageStore();
  const urlKey = location.search
    .slice(1)
    .split("&")
    .reduce(
      (a, b) => {
        const parts = b.split("=");
        a[parts[0]] = parts[1];
        return a;
      },
      {} as Record<string, string>,
    );
  const beta = ref(import.meta.env.VITE_SITE_BETA);
  const active = ref(beta.value);
  const password = ref<string>(
    urlKey.bypass || storage.get("site-lock:stored", ""),
  );
  const permitted = computed(() => {
    return !active.value || password.value === import.meta.env.VITE_APP_BYPASS;
  });

  watch(password, () => storage.put("site-lock:stored", password.value));

  return {
    beta,
    active,
    password,
    permitted,
  };
});
