<template>
  <div class="menu-item-default">
    <RouterLink :to="props.to" class="menu-item"><slot></slot></RouterLink>
  </div>
</template>

<script setup lang="ts">
import { RouterLink, type RouteLocationRaw } from "vue-router";
const props = defineProps<{
  to: RouteLocationRaw;
}>();

// $(function () {
//     $('a.menu-item').on('click', function () {
//         $(this).toggleClass('be-bold');
//     });
// });
</script>

<style scoped>
a {
  text-decoration: none;
}

@media (hover: hover) {
  #app a:hover,
  #app :deep(a:hover) {
    /* font-weight: 500; */
    /* margin-right: -1%; */
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    /* transition: font-weight 0.5s ease; */
    text-shadow: 0.75px 0 black;
  }
}

.menu-item-default {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 9px 10px;
  position: relative;
  font-weight: var(--heading-4-font-weight);
  margin: 0 12px;
  transition: all 0.5s ease;
}

.menu-item-default a.menu-item {
  position: relative;
  width: fit-content;
  font-family: var(--heading-4-font-family);
  font-weight: var(--heading-4-font-weight);
  color: #000000;
  font-size: var(--heading-4-font-size);
  text-align: center;
  letter-spacing: var(--heading-4-letter-spacing);
  line-height: var(--heading-4-line-height);
  font-style: var(--heading-4-font-style);
  display: flex;
  /*padding: var(--Button-Spacing-Large, 10px);*/
  align-items: center;
  transition: all 0.4s ease;
  text-shadow: none;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}

.group {
  align-items: center;
  display: flex;
  gap: 8px;
  list-style: none;
  margin: auto;
  max-width: 1440px;
  padding: 0px 0.5rem;
  width: 100%;
}

.menu-item {
  display: inline-flex;

  font-weight: 400;
  line-height: 100%;
  border: 1.5px solid transparent;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  margin-right: -3px;
  -webkit-box-pack: center;
  justify-content: center;
}

@media (max-width: 1199px) and (min-width: 801px) {
  .menu-item-default .menu-item {
    min-width: auto;
  }
}
</style>
