const medium =
  /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
const strong = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

import pwned from "@/assets/pwnedpasswords-v2-top20k.json";
import api from "@virgodev/bazaar/functions/api";

export function validatePassword(pw: string): string {
  if (pwned.includes(pw)) {
    return "Your password is too common";
  }
  if (!medium.test(pw) && !strong.test(pw)) {
    return "Please choose a stronger password";
  }
  return "";
}

export async function validateUsername(username: string): Promise<string> {
  const response = await api({
    url: "check-username/",
    json: { username },
    method: "POST",
  });
  if (response.ok) {
    return "";
  } else if (response.status === 400) {
    return "This username is already in use. Please try again";
  }
  return "Username check failed. Please try again in a minute";
}
