import { useAddressStore } from "@/stores/address";
import { useCardsStore } from "@/stores/cards";
import { useCartStore } from "@/stores/cart";
import { useFirebaseStore } from "@/stores/firebase";
import { useOrderStore } from "@/stores/orders";
import { usePromosStore } from "@/stores/promos";
import type {
  Address,
  Order,
} from "@paparazzi/types/paparazzi.api.serializers";
import { timer } from "@paparazzi/utils/promise";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import { unpersist } from "@virgodev/vue-models/utils/persist";
import imageCompression from "browser-image-compression";

export function formatDate(iso: string) {
  const dateObj = new Date(iso);

  const date = `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;

  let postfix = "AM";
  let hours = dateObj.getHours();
  if (dateObj.getHours() >= 12) {
    postfix = "PM";

    if (hours > 12) {
      hours = hours - 12;
    }
  }
  const time = `${hours}:${dateObj.getMinutes()}:${dateObj.getSeconds()}${postfix}`;

  return `${date} - ${time}`;
}

export function destem(plural: string): string {
  let retval = plural;
  if (/Earrings$/.test(plural)) {
    // do nothing, there are always two earrings
  } else if (/Accessories$/.test(plural)) {
    retval = plural.replace("Accessories", "Accessory");
  } else if (/s$/.test(plural)) {
    retval = plural.substring(0, plural.length - 1);
  }
  return retval;
}

export function getOrderLabel(order: Order): string {
  let label = `Q${order.id}`;
  if (order.online_order) {
    label = `#${order.online_order}`;
  }
  return label;
}

export function areAddressEqual(
  a: Address | undefined,
  b: Address | undefined,
) {
  if (!a || !b) {
    return false;
  }
  if (a.id && b.id) {
    return a.id === b.id;
  }
  return (
    a.address === b.address &&
    a.address2 === b.address2 &&
    a.zip_code === b.zip_code
  );
}

export function blankAddress(): Address {
  return {
    name: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    county: "",
    zip_code: "",
  };
}

export async function clearUserData() {
  const storage = useLocalStorageStore();
  const orders = useOrderStore();
  const cards = useCardsStore();
  const addresses = useAddressStore();
  const firebase = useFirebaseStore();
  const cart = useCartStore();
  const promos = usePromosStore();

  // copy directly to remove extra keys
  const sitelock = storage.get("site-lock:stored", "");
  const debug = localStorage.debug;
  const isTester = localStorage.tester;
  localStorage.clear();
  if (isTester) {
    localStorage.tester = isTester;
  }
  localStorage.debug = debug || "";

  storage.put("site-lock:stored", sitelock);

  await unpersist();
  await firebase.clear();
  if (orders.store) {
    orders.list = [];
    orders.store.clear();
  }
  if (cards.store) {
    cards.list = [];
    cards.store.clear();
  }
  addresses.clear();
  await cart.clear();
  await promos.clear();

  // wait for localStorage to save data
  await timer(1000);
}

export async function loadImage(
  file: File,
  twidth: number,
  theight: number,
): Promise<string> {
  const compressed = await imageCompression(file, {
    maxSizeMB: 2.4,
    maxWidthOrHeight: Math.max(twidth, theight),
    useWebWorker: false,
  });

  let url = await new Promise<string>((resolve, reject) => {
    if (compressed) {
      let reader = new FileReader();
      reader.onload = async (e) => {
        let result = "";
        if (e.target) {
          result = "" + e.target.result; //.split(",", 2)[1];
        }
        resolve(result);
      };
      reader.readAsDataURL(compressed);
    }
  });

  return url;

  // if (url) {
  //   let orientation = await this.getOrientation(file);
  //   return await this.thumbnail(url, twidth, theight, orientation);
  // }
  // return null;
}
