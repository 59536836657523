import copy from "@virgodev/bazaar/functions/copy";
import { nextTick } from "vue";
import { useCardsStore } from "../cards";
import { useCheckoutStore } from "../checkout";

export async function saveCard(cvc: string): Promise<string> {
  const cards = useCardsStore();
  const checkout = useCheckoutStore();

  await nextTick();
  if (checkout.newCard) {
    try {
      const obj = {
        cvc,
        ...copy(checkout.newCard),
      };

      const errors = [];
      for (const key of [
        "first_name",
        "last_name",
        "card",
        "cvc",
        "year",
        "month",
      ]) {
        if (!obj[key]) {
          errors.push(`${key} is required`);
        }
      }
      if (errors.length > 0) {
        return errors.join(", ");
      }

      const { error, id } = await cards.save(obj);
      if (error) {
        return error;
      }
      if (id) {
        if (checkout.newCard.card) {
          checkout.newCard.card = "XXXX" + checkout.newCard.card.slice(-5, -1);
        }
        checkout.newCard.id = obj.id;
      }
    } catch (e) {
      console.log("error", e);
      if (e instanceof Error) {
        return e.toString();
      } else {
        return `${e}`;
      }
    }
    return "";
  } else {
    return "Card not complete";
  }
}
